// @ts-strict-ignore
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import { CHARTCONFIG } from './charts/charts.config';

import { VirtualService } from 'insig-app/services/virtual/virtual.service';
import { DoctorSettingsService } from 'insig-app/services/doctorSettings.service';
import { MainDashboardComponent } from './components/main/main-dashboard.component';
import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { VirtualCareService } from 'insig-app/services/virtual-care/virtual-care.service';
import { SchedulerService } from 'insig-app/services/scheduler.service';
import { SurveyDataService } from 'insig-app/services/survey-data/survey-data.service';
import { InitNoteService } from 'insig-app/services/initNote.service';

import { Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';

import {
  SurveyDataTable,
  SurveyDataTableItem,
  AllCompanySurveyData,
} from 'insig-types/survey-data/table';
import { Survey } from 'insig-types/surveys';

import firebase from 'firebase/compat/app';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

interface PatientAppointment {
  patientName: string;
  practitioner: string;
  date: string;
  documentSent: string;
  approvedStatus: string;
  status: string;
  [key: string]: any;
}

@Component({
  selector: 'insig-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  // graphql queries

  private doctorListQuery = gql`
    query DoctorListQuery($companyID: ID!, $idToken: ID!) {
      getCompanyUserList(cid: $companyID, token: $idToken) {
        first
        last
        phone
        uid
        valid
        image
        email
        company
        address
        type {
          admin
        }
        acceptVirtual
      }
    }
  `;

  private userDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        first
        last
        phone
        email
        company
        address
        title
        qualifications
        licenseCode
        type {
          admin
          insigSuperAdmin
        }
        image
        apiToken
      }
    }
  `;

  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        name
        companyUrl
        brandingName
        companyPlan
        EMREmailEnabled
        EMRInboxEmail
        email
        website
      }
    }
  `;
  //

  private daysOfTheWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ] as const;

  @ViewChild(MainDashboardComponent)
  mainDashboard: MainDashboardComponent;
  config = CHARTCONFIG;
  public menuItems: any = [
    {
      iden: 'dashboard',
      icon: 'dashboard',
      title: 'DASHBOARD',
    },
    {
      iden: 'companyPlans',
      icon: 'assignment',
      title: 'COMPANY PLANS',
    },
    {
      iden: 'userGroups',
      icon: 'account_box',
      title: 'USER GROUPS',
    },
  ];

  public selectedTab = 'scheduler';
  private userSubscription: any;
  private uid: any;
  public userData: any;
  private companyData: any;
  public companyPlan = false;

  public surveysStats: any = [];
  public bookingsStats: any = [];
  public paymentsStats: any = [];
  public newPatientsStats: any = [];
  public viewsStats: any = [];
  public patientAdminLoading = false;
  public dailyViewsStats: any = [];

  public surveyData: AllCompanySurveyData | null;
  public surveyDataInstances: SurveyDataTable | null;
  public surveyDataTableItems: SurveyDataTableItem[] = [];

  private librarySurveysSubscription: Subscription;
  private patientAppointmentsSubs: Subscription[] = [];
  private patientAppointmentsSearchedSub: Subscription;
  private surveyDataInstanceSubscription: Subscription[] = [];
  private unsubCompanyServices: any;
  private unsubCompanyCategories: any;
  public companyServices: any;
  public companyCategories: any;
  public librarySurveys: Survey[];
  public isPatientAdmin = false;
  public patientAppointments: any[] = [];
  public patientAppointmentsSearched: any[] = [];
  public userListFinal: any;
  private currentLastApptRef: firebase.firestore.QueryDocumentSnapshot;
  private currentLastSurveyDataRef: firebase.firestore.QueryDocumentSnapshot;

  constructor(
    private firebaseAuthService: FirebaseAuthService,
    private router: Router,
    public changeDetector: ChangeDetectorRef,
    private virtualService: VirtualService,
    private loadSurveyService: LoadSurveyService,
    private patientUserDataService: PatientUserDataService,
    private schedulerService: SchedulerService,
    private surveyDataService: SurveyDataService,
    private apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.uid = user.uid;
          const isDoctor = !!(await this.getUserData());
          if (isDoctor) {
            this.getProfileData();
          } else {
            this.userData = await this.getPatientUserData(user);
            this.getPatientAppointments();
            this.isPatientAdmin = true;
            this.selectedTab = 'history';
            this.setMenuItems();
          } // end if else
        } else {
          this.router.navigate(['/auth/login']);
        }
      });

    this.loadLibrarySurveys();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.unsubCompanyServices) {
      this.unsubCompanyServices.unsubscribe();
    }

    if (this.unsubCompanyCategories) {
      this.unsubCompanyCategories.unsubscribe();
    }

    if (this.patientAppointmentsSubs.length > 0) {
      this.detachPatientAppointmentsListeners();
    }
    if (this.patientAppointmentsSearchedSub) {
      this.patientAppointmentsSearchedSub.unsubscribe();
    }

    if (this.surveyDataInstanceSubscription.length > 0) {
      this.detachSurveyDataInstanceListeners();
    }

    this.librarySurveysSubscription?.unsubscribe();
  }

  loadLibrarySurveys(): void {
    this.librarySurveysSubscription = this.loadSurveyService
      .watchLibrarySurveysFromFirestore()
      .subscribe((surveys) => {
        this.librarySurveys = this.sortLibraryFunc(surveys);
      });
  }

  detachPatientAppointmentsListeners() {
    this.patientAppointmentsSubs.forEach((listener) => listener.unsubscribe());
    this.patientAppointmentsSubs = [];
  }

  detachSurveyDataInstanceListeners() {
    this.surveyDataInstanceSubscription.forEach((listener) =>
      listener.unsubscribe()
    );
    this.surveyDataInstanceSubscription = [];
  }

  cleanPatientAppointments(appts): PatientAppointment[] {
    return appts.map((appt) => {
      return {
        ...appt,
        patientName: `${
          appt.familyID ? appt.familyData.data.first : this.userData.first
        } ${appt.familyID ? appt.familyData.data.last : this.userData.last}`,
        phone: appt.familyID ? appt.familyData.data.phone : this.userData.phone,
        address: appt.familyID
          ? appt.familyData.data.address
          : this.userData.address,
        city: appt.familyID ? appt.familyData.data.city : this.userData.city,
        province: appt.familyID
          ? appt.familyData.data.province
          : this.userData.province,
        day: appt.familyID ? appt.familyData.data.day : this.userData.day,
        month: appt.familyID ? appt.familyData.data.month : this.userData.month,
        year: appt.familyID ? appt.familyData.data.year : this.userData.year,
        practitioner: `${
          !!appt.doctor && !!appt.doctor.title ? appt.doctor.title : ''
        }
        ${appt.doctor ? appt.doctor.first : ''} ${
          appt.doctor ? appt.doctor.last : ''
        }`,
        date: `${new Date(appt.event.start).toLocaleDateString()} ${new Date(
          appt.event.start
        ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
        documentSent:
          !!appt.documentSent && !!appt.approved
            ? 'Sent & Approved'
            : appt.documentSent
            ? 'Documents Sent'
            : 'Completed, Not Sent',
        approvedStatus: appt.approved
          ? '‌‌ ‌‌ ‌‌ ‌‌ ‌‌ ‌‌ ‌‌ Y'
          : '‌‌ ‌‌ ‌‌ ‌‌ ‌‌ ‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌N',
        status:
          appt.status === 'cancelled'
            ? 'Cancelled & Refunded'
            : !!appt.status && appt.approved === false
            ? 'Change in Progress'
            : appt.status === 'completed'
            ? 'Completed'
            : appt.status
            ? 'Rebook Required'
            : !appt.status
            ? 'Not Yet Completed'
            : '',
      };
    });
  }

  getSearchedPatientAppointments(patientSearch) {
    // let { first, last } = event;
    console.log(patientSearch);
    this.patientAdminLoading = true;
    if (this.patientAppointmentsSearchedSub) {
      this.patientAppointmentsSearchedSub.unsubscribe();
    }

    this.patientAppointmentsSearchedSub = this.schedulerService
      .getSearchedPatientAppointments(this.uid, patientSearch)
      .pipe(
        map((appts) => {
          // let appts = data.appts;
          // this.currentLastApptRef = data.lastApptRef;
          return this.cleanPatientAppointments(appts);
        })
      )
      .subscribe((snapshot) => {
        console.log(snapshot);

        const filtered = snapshot.reduce((acc, current) => {
          const x = acc.find((item) => item.linkID === current.linkID);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.patientAdminLoading = false;
        this.patientAppointmentsSearched = filtered;
      });
  }

  getMorePatientAppointments() {
    this.patientAdminLoading = true;
    this.patientAppointmentsSubs.push(
      this.schedulerService
        .getMorePatientAppointments(this.uid, this.currentLastApptRef)
        .pipe(
          map((data) => {
            const appts = data.appts;
            this.currentLastApptRef = data.lastApptRef;
            return this.cleanPatientAppointments(appts);
          })
        )
        .subscribe((snapshot) => {
          // console.log(snapshot)
          this.patientAdminLoading = false;
          this.patientAppointments = this.patientAppointments.concat(snapshot);
        })
    );
  }

  getPatientAppointments() {
    this.patientAdminLoading = true;
    this.detachPatientAppointmentsListeners();
    this.patientAppointmentsSubs.push(
      this.schedulerService
        .getAllPatientAppointments(this.uid)
        .pipe(
          map((data) => {
            const appts = data.appts;
            this.currentLastApptRef = data.lastApptRef;
            return this.cleanPatientAppointments(appts);
          })
        )
        .subscribe((snapshot) => {
          // console.log(snapshot)
          this.patientAdminLoading = false;
          this.patientAppointments = snapshot;
        })
    );
  }

  async getPatientUserData(user: firebase.User): Promise<any> {
    return this.patientUserDataService
      .getPatientData(user.uid)
      .pipe(take(1))
      .toPromise();
  }

  setMenuItems() {
    if (!!this.companyData && this.companyData.companyPlan === true) {
      this.companyPlan = true;
      this.menuItems = [
        {
          iden: 'dashboard',
          icon: 'dashboard',
          title: 'DASHBOARD',
        },
        {
          iden: 'surveyData',
          icon: 'data_usage',
          title: 'SURVEY DATA',
        },
        {
          iden: 'companyPlans',
          icon: 'assignment',
          title: 'COMPANY PLANS',
        },
        {
          iden: 'userGroups',
          icon: 'account_box',
          title: 'USER GROUPS',
        },
        {
          iden: 'scheduler',
          icon: 'date_range',
          title: 'PROVIDERS',
        },
        {
          iden: 'theme',
          icon: 'settings',
          title: 'THEME',
        },
      ];
    } else if (this.companyData) {
      this.companyPlan = false;
      this.menuItems = [
        {
          iden: 'dashboard',
          icon: 'dashboard',
          title: 'DASHBOARD',
        },
        {
          iden: 'surveyData',
          icon: 'data_usage',
          title: 'SURVEY DATA',
        },
        {
          iden: 'scheduler',
          icon: 'date_range',
          title: 'PROVIDERS',
        },
        {
          iden: 'theme',
          icon: 'settings',
          title: 'THEME',
        },
      ];
    } else if (this.isPatientAdmin) {
      this.menuItems = [
        {
          iden: 'history',
          icon: 'history',
          title: 'HISTORY',
        },
        {
          iden: 'dashboard',
          icon: 'dashboard',
          title: 'DASHBOARD',
        },
      ];
    }
    if (!!this.mainDashboard) {
      this.mainDashboard.resizeChart(true);
    }
  }

  sortFunc(array) {
    return array.slice().sort((a, b) => {
      return a.last.localeCompare(b.last);
    });
  }

  sortLibraryFunc(array: Survey[]): Survey[] {
    return array.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  async getUserData(): Promise<any> {
    try {
      console.log('getting userdata');
      const userDataQuery = await this.apollo
        .query<{ getUserData: any }>({
          query: this.userDataQuery,
          variables: {
            userID: this.uid,
            token: await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken(),
          },
        })
        .toPromise();
      const userData = userDataQuery.data.getUserData;
      console.log('userData: ', userData);
      if (!userData) {
        return false;
      } else {
        this.userData = userData;
      }
      return true;
    } catch (error) {
      switch (error.code) {
        default:
          console.log(error);
          break;
        case 'PERMISSION_DENIED':
          console.log('Wrong uid. User may have logged out.');
          break;
      }
      throw error;
    }
  }

  getUTCTimeStamp(date) {
    return date.getTime() - date.getTimezoneOffset() * 60 * 1000;
  } // end func

  arrayUnique(array: any[]) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) {
          a.splice(j--, 1);
        }
      }
    }

    return a;
  }

  getSurveyDataInstances(data) {
    // console.log(data)
    const surveyID = data.id;
    const newItem = data.new;
    let lastDataRef = this.currentLastSurveyDataRef || undefined;
    if (newItem) {
      this.detachSurveyDataInstanceListeners();
      lastDataRef = undefined;
    }
    // console.log(newItem, lastDataRef)
    // this.currentLastSurveyDataRef;
    this.surveyDataInstanceSubscription.push(
      this.surveyDataService
        .getSurveyDataInstances(this.userData.company, surveyID, lastDataRef)
        .pipe(
          map((surveyDataInstance) => {
            this.currentLastSurveyDataRef = surveyDataInstance.lastDataRef;

            return surveyDataInstance.surveyData;
          })
        )
        .subscribe((instances) => {
          console.log('isntances: ', instances, lastDataRef);
          // remove arrayUnique and replace with Set
          if (lastDataRef) {
            this.surveyDataTableItems = this.surveyDataTableItems.concat(
              instances.data
            );
            this.surveyDataInstances = {
              questionsObj: {
                ...this.surveyDataInstances.questionsObj,
                ...instances.questionsObj,
              },
              questionsArray: this.arrayUnique(
                this.surveyDataInstances.questionsArray.concat(
                  instances.questionsArray
                )
              ),
              data: this.surveyDataInstances.data.concat(instances.data),
            };
          } else {
            this.surveyDataInstances = instances;
            this.surveyDataTableItems = instances.data;
          }
        })
    );
  }

  sortServicesFunc(array) {
    return array.sort((a, b) => {
      if (!a.data || !a.data.label) {
        return 1;
      }
      if (!b.data || !b.data.label) {
        return -1;
      }
      return a.data.label.localeCompare(b.data.label);
    });
  }

  async getProfileData() {
    const cid = this.userData.company;
    this.getDoctorList(this.userData.company);

    const companyDataQuery: any = await this.apollo
      .query({
        query: this.companyDataQuery,
        variables: {
          companyID: cid,
          token: await this.firebaseAuthService.getIdToken(),
        },
      })
      .toPromise();
    this.companyData = companyDataQuery.data.getCompanyData;
    this.unsubCompanyServices = this.virtualService
      .getCompanyVirtualServices(cid)
      .subscribe((snapshot) => {
        if (snapshot) {
          this.companyServices = this.sortServicesFunc(snapshot);
        }
      });

    this.unsubCompanyCategories = this.virtualService
      .getCompanyVirtualCategories(cid)
      .subscribe((snapshot) => {
        if (snapshot) {
          this.companyCategories = snapshot;
        }
      });

    this.setMenuItems();
  }

  async getDoctorList(companyID) {
    try {
      const userListQuery: any = await this.apollo
        .query({
          query: this.doctorListQuery,
          variables: {
            companyID,
            idToken: await this.firebaseAuthService.idToken$.pipe(take(1)).toPromise(),
          },
        })
        .toPromise();
      let userList = userListQuery.data.getCompanyUserList;
      if (userList) {
        userList = this.sortFunc(userList);
      }
      const userListFinal = [];
      userList.forEach((user) => {
        if (user) {
          userListFinal.push({
            ...user,
            fullName: `${user.first} ${user.last}`
          });
        }
      });
      this.userListFinal = userListFinal;
    } catch (error) {
      console.log(error);
    }
  }
}
